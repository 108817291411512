import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97')
];

export const server_loads = [7,10,13,12,14,15,16,18,19,17,20,21,6,3,22,23,24,26,27,29,30,31,33,34,4,36,35,37,38,40,9];

export const dictionary = {
		"/(site)": [56,[7],[8]],
		"/(site)/about": [58,[7,10],[8]],
		"/(site)/blog": [~59,[7],[8,11]],
		"/(site)/blog/categories/[category]": [61,[7,,13],[8,11]],
		"/(site)/blog/[slug]": [60,[7,,12],[8,11]],
		"/(site)/brand": [62,[7,14],[8]],
		"/(site)/careers": [63,[7,15],[8]],
		"/(site)/change-log": [64,[7,16],[8]],
		"/(site)/change-log/product-area/[area]": [66,[7,16,18],[8]],
		"/(site)/change-log/type/[type]": [67,[7,16,19],[8]],
		"/(site)/change-log/[slug]": [65,[7,16,17],[8]],
		"/(site)/cloud": [68,[7,20],[8]],
		"/(site)/community": [69,[7,21],[8]],
		"/(sign-up)/get-cloud": [49,[,6],[5]],
		"/(site)/get-cloud/aws-marketplace": [~70,[7],[8]],
		"/(site)/get-cloud/aws-test": [~71,[7],[8]],
		"/(sign-up)/get-cloud/callback": [50],
		"/(sign-up)/get-cloud/contact-information": [51,[,6],[5]],
		"/(sign-up)/get-cloud/log-in": [52,[,6],[5]],
		"/(sign-up)/get-cloud/payment-information": [53,[,6],[5]],
		"/(sign-up)/get-cloud/provisioning": [54,[,6],[5]],
		"/(sign-up)/get-cloud/waiting": [55,[,6],[5]],
		"/(home)/home": [41,[2,3]],
		"/(site)/how-it-works": [72,[7,22],[8]],
		"/(site)/in-use": [73,[7,23],[8]],
		"/(site)/in-use/[slug]": [~74,[7,23],[8]],
		"/(site)/news": [75,[7,24],[8,25]],
		"/(site)/news/[slug]": [76,[7,24,26],[8,25]],
		"/(site)/partners": [77,[7,27],[8]],
		"/(site)/preview/[entityId]": [78,[7,28,29],[8]],
		"/(site)/pricing": [79,[7,30],[8]],
		"/(site)/product": [80,[7,31],[8]],
		"/(site)/replay-2023": [~81,[7],[8]],
		"/(site)/replay-2023/videos": [~82,[7],[8,32]],
		"/(site)/replay-2023/videos/[slug]": [~83,[7],[8,32]],
		"/(site)/resources": [84,[7,33],[8]],
		"/(site)/resources/case-studies": [~85,[7,33],[8]],
		"/(site)/resources/case-studies/[slug]": [~86,[7,33],[8]],
		"/(site)/resources/longform": [~87,[7,33],[8]],
		"/(site)/resources/longform/[slug]": [~88,[7,33],[8]],
		"/(site)/resources/on-demand": [~89,[7,33],[8]],
		"/(site)/resources/on-demand/[slug]": [~90,[7,33],[8]],
		"/(site)/security": [91,[7,34],[8]],
		"/(set-up)/setup": [~42,[4]],
		"/(set-up)/setup/all-done": [43,[4]],
		"/(set-up)/setup/choose-an-sdk": [44,[4]],
		"/(set-up)/setup/install-temporal-cli": [45,[4]],
		"/(set-up)/setup/open-the-web-ui": [46,[4]],
		"/(set-up)/setup/run-a-sample-workflow": [47,[4]],
		"/(set-up)/setup/start-development-server": [48,[4]],
		"/(site)/startup-program-aws-activate": [93,[7,36],[8]],
		"/(site)/startup": [92,[7,35],[8]],
		"/(site)/temporal-versus": [~94,[7],[8]],
		"/(site)/temporal-versus/cadence": [95,[7,37],[8]],
		"/(site)/use-cases": [96,[7,38],[8,39]],
		"/(site)/use-cases/[slug]": [97,[7,38,40],[8,39]],
		"/(site)/[slug]": [57,[7,9],[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';